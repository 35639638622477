import React from "react"
import { Link } from 'gatsby'
import Logo from "../../../../static/images/logo/logo.svg"
import Seo from "../../../components/seo"
import BookHeader from "../../../components/bookPageHeader"
import ReactBook from "../../../../static/images/commonImg/reactbook.svg"
import ReactBookTS from "../../../../static/images/commonImg/reactbook-ts.svg"
import ReactBook2 from "../../../../static/images/commonImg/reactbook2.svg"
import ReactBook2TS from "../../../../static/images/commonImg/reactbook2-ts.svg"
import NodeExpressBook from "../../../../static/images/commonImg/nodebook-intro.svg"
import NodeExpressBook2 from "../../../../static/images/commonImg/nodebook-advanced.svg"
//import NextBook2a from "../../../../static/images/commonImg/nextbook2-1.svg"
import NextBook2aTS from "../../../../static/images/commonImg/nextbook2-1ts.svg"
import MernBook from "../../../../static/images/commonImg/mern-book-1.svg"
import GatsbyBook from "../../../../static/images/commonImg/gatsbybook.svg"
import NextBook from "../../../../static/images/commonImg/nextbook.svg"
import JamstackBook from  "../../../../static/images/commonImg/jamstackbook.svg"
import vuebook from "../../../../static/images/commonImg/vuebook.svg"
import NuxtBook from "../../../../static/images/commonImg/nuxtbook.svg"
import JSBook from "../../../../static/images/commonImg/jsbook.svg"
import ProxyBook from "../../../../static/images/commonImg/proxyserver-book.png"
import TSmasterBook from "../../../../static/images/commonImg/typescript-master-book.svg"
import ReactMasterBook from "../../../../static/images/commonImg/react-master-book.svg" 
//import RegisterNewsLetterBody from "../../../../components/registerNewsLetter"

//import AdNextFullstack from "./components/ad-next-fullstack-app-folder"

const generalData = {
    title: "Next.js／React開発入門",
    updated: "2024/3/28",
    urlToApp: "https://nextbook-fullstack-app-folder.vercel.app",
    urlToCode1: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2",
    urlToCode2: "",
}

const booksData = [
    {oldPrice: "", newPrice: "", newRelease: "*2024年2月発売", title: "React全部入り：Reactマスター", img: ReactMasterBook, url: "https://monotein.base.shop/p/00001", description: "Reactを『なんとなく』は使える人が、2日で自信を持ってReactを書ける本！<br/><br/>JavaScriptの基礎の基礎、Reactの基本概念、そして応用までの全範囲をカバーした待望の解説書。<br/><br/>React入門に成功したビギナーが、たしかな理解とスキルを身につけて、自走できるようになる一冊。"},
    {oldPrice: "", newPrice: "", newRelease: "*2024年3月発売", title: "React + TS徹底フォーカス：TypeScriptマスター with React", img: TSmasterBook, url: "https://monotein.base.shop/p/00002", description: "2日でTypeScript + Reactの苦手意識を捨てされる本。<br/><br/>「TypeScriptをReactで使う」という点にフォーカスした貴重なハンズオン（作りながら学ぶ）形式の実用書。<br/><br/>TypeScriptに苦手意識を持っていても、自信をもってReact + TypeScript開発を進められるようになります。"},
    {oldPrice: "", newPrice: "", newRelease: "", title: "MERNでつくるフルスタックアプリ（前編・後編）", img: MernBook , url: "https://www.amazon.co.jp/dp/B0BJN4H1NF", description: "Node.js、Express、React、MongoDBを使ってバックエンド、フロントエンド開発を行ない、アプリを作ります。<br/><br/>フルスタックアプリ開発の入門に最適な一冊。"},
    {oldPrice: "", newPrice: "", newRelease: "", title: "はじめてつくるReactアプリ with TypeScript", img: ReactBookTS, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: "「React開発のスタンダードReact + TypeScriptを学んでみたい」<br/><br/>という人向けのReact + TypeScript入門書です。"},
]

const booksDataForSlider = [
    {newRelease: "", title: "", img: ReactBook, url: "https://www.amazon.co.jp/dp/B08XWMVX76", description: ""},
    {newRelease: "", title: "", img: ReactBookTS, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: ""},
    {newRelease: "", title: "", img: ReactBook2, url: "https://www.amazon.co.jp/dp/B09693ZJCV", description: ""},
    {newRelease: "", title: "", img: ReactBook2TS, url: "https://www.amazon.co.jp/dp/B09Q57Q9T1", description: ""},
    {newRelease: "", title: "", img: GatsbyBook, url: "https://www.amazon.co.jp/dp/B0BNQBDHFH", description: ""},
    {newRelease: "", title: "", img: JamstackBook, url: "https://www.amazon.co.jp/dp/B09P3ZVVR2", description: ""},
    {newRelease: "", title: "", img: NodeExpressBook, url: "https://www.amazon.co.jp/dp/B09HQZ1BW8", description: ""},
    {newRelease: "", title: "", img: NodeExpressBook2, url: "https://www.amazon.co.jp/dp/B09X56PSCQ", description: ""},
    {newRelease: "", title: "", img: JSBook, url: "https://www.amazon.co.jp/dp/B0C9C9SVFG", description: ""},
    {newRelease: "", title: "", img: vuebook, url: "https://www.amazon.co.jp/dp/B0B46QNTNL", description: ""},
    {newRelease: "", title: "", img: NuxtBook, url: "https://www.amazon.co.jp/dp/B0B5N8QJ2Y", description: ""},
    {newRelease: "", title: "", img: ProxyBook, url: "https://www.amazon.co.jp/dp/B0BBMQS28M", description: ""},
]

const links = [
    {
        chapterNum: "はじめに",
        data: [
            {name: "完成見本アプリ", url: "https://nextbook-fullstack-app-folder.vercel.app"},
            {name: "JSON Formatter", url: "https://chrome.google.com/webstore/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa/related"}
        ]
    },
    {
        chapterNum: 1,
        data: [
            {name: "全アイテムのデータページ", url: "https://nextbook-fullstack-app-folder.vercel.app/api/item/readall"},
            {name: "個別のアイテムページ", url: "https://nextbook-fullstack-app-folder.vercel.app/item/readsingle/65478ff981349fcd3162bf87"},
            {name: "個別のアイテムのデータページ", url: "https://nextbook-fullstack-app-folder.vercel.app/api/item/readsingle/65478ff981349fcd3162bf87"},
            {name: "Node.js", url: "https://nodejs.org/en/download"},
            {name: "パッケージ情報", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2/blob/master/package.json"},
            {name: "VS Code", url: "https://code.visualstudio.com/download"},
            {name: "Thunder Client", url: "https://marketplace.visualstudio.com/items?itemName=rangav.vscode-thunder-client"},
            {name: "エラー発生時の対処方法", url: "https://monotein.com/blog/how-to-find-and-fix-errors"},
        ]
    },
    {
        chapterNum: 2,
        data: [
            {name: "第2章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2/tree/chapter2"},
        ]
    },
    {
        chapterNum: 3,
        data: [
            {name: "MongoDB", url: "https://www.mongodb.com"},
            {name: "第3章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2/tree/chapter3"},
        ]
    },
    {
        chapterNum: 4,
        data: [
            {name: "JSON Web Token", url: "https://jwt.io"},
            {name: "第4章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2/tree/chapter4"},
        ]
    },
    {
        chapterNum: 5,
        data: [
            {name: "Vercel", url: "https://vercel.com"},
            {name: "第5章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2/tree/chapter5"},
        ]
    },
    {
        chapterNum: 6,
        data: [
            {name: "1つ目のアイテム", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2/blob/master/item-data/1st-item.md"},
            {name: "2つ目のアイテム", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2/blob/master/item-data/2nd-item.md"},
            {name: "3つ目のアイテム", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2/blob/master/item-data/3rd-item.md"},
            {name: "4つ目のアイテム", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2/blob/master/item-data/4th-item.md"},
            {name: "5つ目のアイテム", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2/blob/master/item-data/5th-item.md"},
            {name: "6つ目のアイテム", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2/blob/master/item-data/6th-item.md"},
            {name: "画像", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2/tree/master/public"},
            {name: "ファビコン", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2/blob/master/app/favicon.ico"},
            {name: "サーバー／クライアントコンポーネントの解説（Next.js公式サイト）", url: "https://nextjs.org/docs/app/building-your-application/rendering/composition-patterns"},
            {name: "第6章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2/tree/chapter6"},
        ]
    },
    {
        chapterNum: 7,
        data: [
            {name: "第7章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2/tree/chapter7"},
        ]
    },
    {
        chapterNum: 8,
        data: [
            {name: "キャッシュの設定について（Next.js公式サイト）", url: "https://nextjs.org/docs/app/building-your-application/caching"},
            {name: "<Image>の設定について（Next.js公式サイト）", url: "https://nextjs.org/docs/app/api-reference/components/image#props"},
            {name: "CSS", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2/blob/master/app/globals.css"},
            {name: "第8章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2/tree/chapter8"},
        ]
    },
    {
        chapterNum: 9,
        data: [
            {name: "第9章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2/tree/chapter9"},
        ]
    },
    {
        chapterNum: 10,
        data: [
            {name: "Cloudinary", url: "https://cloudinary.com"},
            {name: "第10章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-fullstack-app-folder-v2"},
        ]
    },
]

const NextFullstackAppFolderBookLinks = () => (
    <div className="container2">
        <Seo title={generalData.title} noindex={true} />
        <div><Link to="/blog"><img src={Logo} style={{width: "180px", margin: "auto", display: "block", paddingTop: "25px"}}/></Link></div>
        <h3>『{generalData.title}』リンクページ</h3>
        <h4>更新日：<span>{generalData.updated}</span></h4>
        <ul>
            <h3 style={{backgroundColor: "#525252", display: "inline-block", color: "#fff", padding: "0 40px"}}>全般</h3>
       {/*      <li><span style={{color: "red"}}>*アイテムの作成／削除／編集のあと、データの変更が反映されない問題の解決策（後編・フロントエンド開発）</span>：<a rel="noopener noreferrer" target="_blank" href="https://monotein-books.netlify.app/nextjs-app-fullstack-page-not-update">https://monotein-books.netlify.app/nextjs-app-fullstack-page-not-update</a></li>
            <br/> */}
            {generalData.urlToApp && <li>見本アプリ：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToApp}>{generalData.urlToApp}</a></li>}
            <li>ソースコードダウンロード（本書完了時）：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode1}>{generalData.urlToCode1}</a></li>
        </ul>

       <hr/>

        <ul>
            {links.map((x, index) => 
                <div key={index}>
                    <h3>{typeof x.chapterNum === "string" ? x.chapterNum : `第${x.chapterNum}章`}</h3>
                    {x.data.map((y, index) => 
                        <li key={index}>{y.name}： <a rel="noopener noreferrer" target="_blank" href={y.url}>{y.url}</a></li>
                    )}
                </div>
            )}
        </ul>
        <div style={{marginTop: "90px" }}></div>
    </div>
)

export default NextFullstackAppFolderBookLinks
